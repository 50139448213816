<script setup lang="ts">
import { DynamicCardComponentStyle, ContentButtonVariant } from '#imports'

const { title,
  styling = DynamicCardComponentStyle.Default,
  headingSize = 'md',
  buttonVariant = ContentButtonVariant.Primary,
  cards = [],
  isCarousel = false,
  maxCardsPerRow = undefined,
} = defineProps<{
  title?: string
  styling?: DynamicCardComponentStyle
  headingSize?: 'md' | 'xl'
  buttonVariant?: ContentButtonVariant
  cards?: DynamicCard[]
  isCarousel?: boolean
  maxCardsPerRow?: number
}>()

const cardCount = cards.length

// Determine the columns of a single card based on the number of valid cards
// Note that the grid also has different classes per breakpoint.
// So I actually don't completely know the logic anymore.
// I think we should refactor this by giving all cards 1 column with and then changing the
// grid for each breakpoint. (and also taking into account the maxCardsPerRow prop)
const gridColClass = computed(() => {
  if (maxCardsPerRow && cardCount >= maxCardsPerRow) {
    return `col-span-${12 / maxCardsPerRow}`
  }

  if (cardCount == 2) {
    return 'col-span-3'
  }

  return 'col-span-6 sm:col-span-4 md:col-span-3'
})

// Carousel code
const showLeftButton = ref(false)
const showRightButton = ref(isCarousel)
const carousel = ref<HTMLElement | null>(null)
// Get a ref to the first card to determine its width for scrolling behaviors.
const cardElement = ref<HTMLElement | null>(null)
const setCardRef = (componentInstance: any) => {
  if (componentInstance && !cardElement.value) {
    cardElement.value = componentInstance.rootElement
  }
}

const threshold = 10

const scroll = (direction: 'left' | 'right') => {
  // console.log(`[scroll] direction: ${direction}`)
  if (!carousel.value || !cardElement.value) return

  // 250px for fallback
  const cardWidth = cardElement.value.offsetWidth || '250' // TODO: add a bit more for the gap?
  const scrollAmount = direction === 'left' ? -cardWidth : cardWidth // -100 : 100
  // console.log(`[scroll] scrollAmount: ${scrollAmount}`)

  // Calculate the new scroll position
  const currentScroll = carousel.value.scrollLeft
  const newScrollLeft = Math.max(0, Number(currentScroll) + Number(scrollAmount))
  // console.log(`[scroll] newScrollLeft: ${newScrollLeft}`)
  carousel.value.scrollTo({
    left: newScrollLeft,
    behavior: 'smooth',
  })

  // Check scroll position after animation completes
  setTimeout(toggleButtons, 300)
}

// Remove the early return and improve scroll checking
const toggleButtons = () => {
  if (!carousel.value) return

  const maxScrollLeft = carousel.value.scrollWidth - carousel.value.clientWidth

  showRightButton.value = carousel.value.scrollLeft < maxScrollLeft - threshold
  showLeftButton.value = carousel.value.scrollLeft > threshold
}

// Debounce the scroll event
const debouncedToggleButtons = useDebounceFn(toggleButtons, 100)

onMounted(() => {
  if (isCarousel && carousel.value) {
    carousel.value.addEventListener('scroll', debouncedToggleButtons)
    // Initial check
    toggleButtons()
  }
})

onBeforeUnmount(() => {
  if (carousel.value) {
    carousel.value.removeEventListener('scroll', debouncedToggleButtons)
  }
})

// Todo: determine bases on background color?
const showGradients = styling != DynamicCardComponentStyle.PhotoBackground

// Should the buttons be shown in the header or as an overlay on the cards?
const hasHeader = !!title

// Show the buttons in the header or as an overlay on the cards?
const scrollButtonsPosition: 'header' | 'overlay' = !hasHeader ? 'overlay' : 'header'
// Apply snap scrolling to the carousel?
// Need to figure out a good scroll amount for the gap, see above.
const snap = true

</script>

<template>
  <ContentBlockViewPartialContainer padding="default">
    <div v-if="title" class="my-4 flex justify-between gap-4">
      <ContentBlockViewPartialTitle :title="title" />

      <div v-if="scrollButtonsPosition === 'header'" class="flex items-center gap-x-1">
        <button
          class="bg-secondary-500 rounded-full p-2 px-4 text-white"
          @click="() => scroll('left')"
        >
          <Icon name="ph:arrow-left" />
        </button>
        <button
          class="bg-secondary-500 rounded-full p-2 px-4 text-white"
          @click="() => scroll('right')"
        >
          <Icon name="ph:arrow-right" />
        </button>
      </div>
    </div>
    <div class="relative">
      <div
        ref="carousel"
        class="py-4"
        :class="{
          'snap-x snap-mandatory snap-start': snap,
          'hidden-scrollbar scroll flex w-full gap-x-2 overflow-x-auto scroll-smooth': isCarousel,
          'grid grid-cols-3 gap-6 sm:grid-cols-8 md:grid-cols-12': !isCarousel,
        }"
      >
        
        <div
          v-if="cardCount === 2 && !isCarousel"
          class="col-span-3 hidden md:block"
        />

        <ContentBlockViewPartialCard
          v-for="(card, index) in cards"
          :key="index"
          :ref="setCardRef"
          :styling="styling"
          :card="card"
          :heading-size="headingSize"
          :button-variant="buttonVariant"
          :class="[
            gridColClass,
            isCarousel
              ? // On mobile, show 2 cards at a time, with a bit of spacing. On sm show 3. On md, show 4.4 cards at a time.
                'w-[calc(100%/2-1rem)] flex-none sm:w-[calc(100%/3-1rem)] md:w-[calc(100%/4.4-1rem)]'
              : '',
          ]"
        />

        
        <div
          v-if="cardCount === 2 && !isCarousel"
          class="col-span-3 hidden md:block"
        />

        
        <Transition
          enter-active-class="transition-opacity duration-200 ease-out"
          enter-from-class="opacity-0"
          enter-to-class="opacity-100"
          leave-active-class="transition-opacity duration-200 ease-in"
          leave-from-class="opacity-100"
          leave-to-class="opacity-0"
        >
          <div v-if="showLeftButton" :class="{'carousel-fade-left': showGradients}" />
        </Transition>

        
        <div
          v-if="isCarousel && scrollButtonsPosition === 'overlay'"
          class="carousel-button absolute left-0 top-0 z-10 flex h-full w-0 flex-col justify-center"
          :class="{ hidden: !showLeftButton }"
        >
          <BaseButtonIcon
            shape="full"
            color="muted"
            @click="() => scroll('left')"
          >
            <Icon name="ph:arrow-left" />
          </BaseButtonIcon>
        </div>

        
        <Transition
          enter-active-class="transition-opacity duration-200 ease-out"
          enter-from-class="opacity-0"
          enter-to-class="opacity-100"
          leave-active-class="transition-opacity duration-200 ease-in"
          leave-from-class="opacity-100"
          leave-to-class="opacity-0"
        >
          <div v-if="showRightButton" :class="{ 'carousel-fade-right': showGradients }" />
        </Transition>

        
        <div
          v-if="isCarousel && scrollButtonsPosition === 'overlay'"
          class="carousel-button absolute right-0 top-0 flex h-full w-0 flex-col justify-center"
          :class="{ hidden: !showRightButton }"
        >
          <BaseButtonIcon
            shape="full"
            color="muted"
            class="-translate-x-full"
            @click="() =>scroll('right')"
          >
            <Icon name="ph:arrow-right" />
          </BaseButtonIcon>
        </div>
      </div>
    </div>
  </ContentBlockViewPartialContainer>
</template>

<style>
.hidden-scrollbar {
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}

/* Adjust the last card's margin to prevent extra space at the end */
.carousel-container > :last-child {
  @apply mr-0;
}

.carousel-fade-right {
  @apply pointer-events-none absolute bottom-0 right-0 top-0 w-12 bg-gradient-to-r from-transparent to-white content-[''];
}

.carousel-fade-left {
  @apply pointer-events-none absolute bottom-0 left-0 top-0 w-12 bg-gradient-to-l from-transparent to-white content-[''];
}

.carousel-button {
  /* Prevent zooming in on mobile when tapping the buttons quickly in succession */
  touch-action: manipulation;
}
</style>
