import {
  array,
  boolean,
  object,
  string,
  type InferType,
} from 'yup'

export enum DynamicCardComponentStyle {
  Default = 'default',
  PhotoBackground = 'photo-background',
  DarkBackground = 'dark-background', // Used for Roermond
}

export enum DynamicCardMediaType {
  NoMedia = 'no-media',
  Image = 'image',
  YouTube = 'youtube',
}

export interface DynamicCard {
  mediaType: DynamicCardMediaType
  image?: ContentFile
  youtubeUrl?: string
  title?: string
  body?: string
  buttonText?: string
  buttonUrl?: string
}

export const dynamicCardSchema = object({
  mediaType: string().oneOf(Object.values(DynamicCardMediaType)).required(),
  image: imageUploadField,
  youtubeUrl: string()
    .optional()
    .matches(
      /^(http(s)?:\/\/)?((w){3}.)?youtu(be|.be)?(\.com)?\/.+/,
      'Enter a valid YouTube URL',
    ),
  title: string().optional(),
  body: string().optional(),
  buttonText: string().optional(),
  buttonUrl: string().optional(),
})

export const dynamicCardsSchema = object({
  title: string().optional(),
  styling: string()
    .oneOf(Object.values(DynamicCardComponentStyle))
    .default(DynamicCardComponentStyle.Default),
  isCarousel: boolean().default(false),
  cards: array(dynamicCardSchema).min(1).required(),
})

export interface DynamicCardPayload
  extends InferType<typeof dynamicCardSchema> {}

export interface DynamicCardsBlockPayload
  extends InferType<typeof dynamicCardsSchema> {}
