<script setup lang="ts">
// ## How this works (new method):
// Each component has a a view and a form component.
// The form schema is injected into the tree (`injectedBlockSchema`)
// Basically all you need to do to add a new component is:
// 1. Add the form component, including the schema.
// 2. Add the view component, wich takes the schema as a prop.
// 3. Add the component type to the enum in .app/content/contentBlockTypes.ts
// 4. Add the localized name(s) to the nl.json and en.json files.

// Fix clashes with enum used as both type and value
import type { AnyObjectSchema } from 'yup'
import type { ContentBlock } from '#imports'

const { open, pageId, position, initialBlock } = defineProps<{
  /**
   * Whether the modal is open.
   */
  open?: boolean
  /**
   * The page id that is currently being edited
   */
  pageId: id
  /**
   * Position of the block
   */
  position: number
  /**
   * The initial content block (for editing)
   */
  initialBlock?: ContentBlock
}>()

const emits = defineEmits<{
  (close: 'close'): void
  (event: 'saved', value: ContentBlock): void
}>()

const { t } = useI18n({ useScope: 'local' })

// NOTE: make sure this is not undefined initially, or the form validation will
// start acting weird. Bug reported: https://github.com/logaretm/vee-validate/issues/4624.
const contentBlockType = ref<string | undefined>(
  initialBlock?.blockType || 'markdown', // TODO: does string type work? remove it?
)
// Instead, we reset the initial value to undefined as soon as the component is mounted.
// (only for the create form, not for update.)
onMounted(() => {
  if (initialBlock) return
  contentBlockType.value = undefined
})

const { contentBlockFormMap } = useContentBlockMaps()
const { t: $t } = useI18n()
const contentBlockTypes = Object.keys(contentBlockFormMap).sort((a, b) => {
  return $t(`content.blockType.${a}`).localeCompare($t(`content.blockType.${b}`))
})

// This is the new method! See Meedoen's component for how to use it.
// How it works is quite simple: we inject a ref with the schema into the
// tree. The child component can then set the value of this ref, which
// can basically be any schema. The schema validation and errors are handled here.
const injectedBlockSchema = ref<AnyObjectSchema | undefined>()
provide('blockFormSchema', injectedBlockSchema)

// Watch forcontent block type and schema changes and reset form when it changes
watch([contentBlockType, injectedBlockSchema], () => {
  console.log(`injectedBlockSchema changed`)
  // Reset form with empty object to ensure proper structure for nested fields
  resetForm({})
}, { deep: true })

// Type signature: const setFieldError: (field: never, message: string | string[] | undefined) => void
const formContext = useForm({
  validationSchema: computed(() => injectedBlockSchema.value),
  initialValues: initialBlock?.data || {},
})

const { handleSubmit, isSubmitting, resetForm, setFieldError } = formContext

// Returns a new object with the files replaced by their contentFile id and url.
const uploadFiles = async (values: Record<string, any>) => {
  // Function to recursively transform values
  const transformValues = async (obj: any) => {
    for (const key in obj) {
      // console.log(`key:`, key)
      if (obj[key] instanceof File) {
        // console.log(`Uploading file for key "${key}":`, obj[key])
        try {
          const contentFile = await createContentFile({
            file: obj[key],
          })
          console.log(`Created content file:`, contentFile)
          obj[key] = contentFile
        }
        catch (error) {
          console.error(`Failed to upload the file for key "${key}":`, error)
          throw error
        }
      }
      else if (Array.isArray(obj[key])) {
        // If the value is an array, check if it contains Files
        const newArray = await Promise.all(obj[key].map(async (item) => {
          if (item instanceof File) {
            // If item is a File, upload it directly
            const contentFile = await createContentFile({
              file: item,
            })
            console.log(`Created content file:`, contentFile)
            return contentFile
          }
          else if (typeof item === 'object' && item !== null) {
            // If item is an object, recursively transform it
            await transformValues(item)
            return item
          }
          return item
        }))
        obj[key] = newArray
      }
      else if (typeof obj[key] === 'object' && obj[key] !== null) {
        // console.log(`Transforming object for key "${key}":`, obj[key])
        // If the value is an object, recursively transform it
        await transformValues(obj[key])
      }
      // else {
      //   console.log(`No file found for key "${key}".`)
      // }
    }
  }

  await transformValues(values)
  return values
}

// This is a bit of a hack to use the useServerErrorHandler while the blockSchemaMap is dynamic.
// I need to think about this. Maybe make it possible to pass in a ref/computed property?
const { handleServerError } = useServerErrorHandler(
  // TODO: need to fix this type error
  injectedBlockSchema.value,
  // TODO: type system complains about setFieldError having a `never` field.
  // @ts-ignore TS2345: Argument of type 'string' is not assignable to parameter of type 'never'.
  setFieldError,
)
const { handleValidationError } = useFormValidationErrorHandler()

const submit = handleSubmit(async (values) => {
  if (!injectedBlockSchema.value) {
    console.error(`No blockschema`)
    return
  }
  if (!contentBlockType.value) {
    console.error(`No content block type`)
    return
  }

  // console.log(`values:`, values)

  try {
    // Check if we have any new files in the payload.
    // If so, we need to upload them first.
    const payloadWithUploadedFiles = await uploadFiles(values)

    const payload = {
      pageId: pageId as number,
      blockType: contentBlockType.value,
      data: payloadWithUploadedFiles,
      position,
    }

    let savedBlock: ContentBlock | undefined
    if (!initialBlock) {
      savedBlock = await createContentBlock(payload)
    }
    else {
      savedBlock = await updateContentBlock(initialBlock.id, payload)
    }

    emits('close')
    emits('saved', savedBlock)
    resetForm()
  }
  catch (error) {
    console.error(`Catched error:`, error)
    // @ts-ignore TS2345: Argument of type 'string' is not assignable to parameter of type 'never'.
    handleServerError(error, injectedBlockSchema.value)
  }
}, handleValidationError)
</script>

<template>
  <form :class="{ hidden: !open }" @submit.prevent="submit">
    <BaseModal
      :open="open"
      size="3xl"
      @close="$emit('close')"
    >
      <template v-if="!initialBlock" #title>
        {{ t('headingTitle') }}
      </template>
      <template
        v-else
        #title
      >
        {{ t('editHeadingTitle') }}
      </template>

      <template v-if="!initialBlock">
        <BaseParagraph>
          {{
            initialBlock ? t('editHeadingText') : t('headingText')
          }}
        </BaseParagraph>

        <BaseSelect v-model="contentBlockType" class="mt-1">
          <option value="">
            Type blok
          </option>
          <option
            v-for="blockType in contentBlockTypes"
            :key="blockType"
            :value="blockType"
          >
            {{ $t(`content.blockType.${blockType}`) }}
          </option>
        </BaseSelect>
      </template>

      <div v-else>
        <BaseHeading>
          {{ $t(`content.blockType.${initialBlock.blockType}`) }}
        </BaseHeading>
      </div>

      <div
        v-if="contentBlockType"
        class="bg-muted-50 mt-4 rounded-xl px-4 pb-4 pt-2"
      >
        <ContentBlockFormRenderer :key="contentBlockType" :content-block-type="contentBlockType" />
      </div>

      <template #buttons>
        <BaseButton @click="$emit('close')">
          {{ $t('cancel') }}
        </BaseButton>

        <SubmitButton :is-submitting="isSubmitting" @click="submit">
          {{
            $t('save')
          }}
        </SubmitButton>
      </template>
    </BaseModal>
  </form>
</template>

<i18n lang="json">
{
  "en": {
    "headingTitle": "Add a block",
    "editHeadingTitle": "Edit block",
    "headingText": "Choose the type of block you want to add:",
    "editHeadingText": "Add a new block"
  },
  "nl": {
    "headingTitle": "Blok toevoegen",
    "editHeadingTitle": "Blok bewerken",
    "headingText": "Kies het type blok dat je wilt toevoegen:",
    "editHeadingText": "Bewerk dit blok."
  }
}
</i18n>
