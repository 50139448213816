<script setup lang="ts">
import { object, string, number, boolean } from 'yup'

const { t } = useI18n({ useScope: 'local' })

// Exclude default pages
const newsTypes = Object.values(PageType).filter(type => type != PageType.Default)

// Schema definition
const newsCarouselSchema = object({
  title: string().label(t('fields.title')),
  maxItems: number().min(1).max(20).default(6).required().label(t('fields.maxItems')),
  showDate: boolean().default(true).label(t('fields.showDate')),
  showSummary: boolean().default(true).label(t('fields.showSummary')),
  pageType: string().oneOf(newsTypes).default(PageType.News).required().label(t('fields.pageType')),
})

// Inject the block form schema
useBlockFormSchema(newsCarouselSchema)
</script>

<template>
  <div class="flex flex-col gap-y-4 pt-4">
    <BaseParagraph class="mt-0">
      {{ t('explanation') }}
    </BaseParagraph>

    <FormField name="title" :label="t('fields.title')" />

    <FormListField
      name="pageType"
      :label="t('fields.pageType')"
      :items="newsTypes"
      :label-factory="item => $t(`page.attributes.pageTypeOptions.${item}`)"
    />

    <FormField
      name="maxItems"
      :label="t('fields.maxItems')"
      type="number"
      min="1"
      max="20"
    />

    <FormCheckboxField
      name="showDate"
      :label="t('fields.showDate')"
    />

    <FormCheckboxField
      name="showSummary"
      :label="t('fields.showSummary')"
    />
  </div>
</template>

<i18n lang="json">
{
  "en": {
    "explanation": "This block displays the latest news items in a horizontal scrollable carousel.",
    "fields": {
      "title": "Title (optional)",
      "maxItems": "Maximum number of news items",
      "showDate": "Show date",
      "showSummary": "Show summary",
      "pageType": "Type of items"
    }
  },
  "nl": {
    "explanation": "Dit blok toont de nieuwste nieuwsberichten in een horizontaal scrollbare carousel.",
    "fields": {
      "title": "Titel (optioneel)",
      "maxItems": "Maximaal aantal nieuwsberichten",
      "showDate": "Toon datum",
      "showSummary": "Toon samenvatting",
      "pageType": "Type items"
    }
  }
}
</i18n>
