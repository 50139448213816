// For backwards compatibility, try to get the image variant from the imageVariantUrls if available.
// This is to solve for the case where we have a content file that was created before we added the imageVariantUrls.
// And also for the case where trying to get an image variant that doesn't exist, in wich case we fall back to the fileUrl,
// and fail silently.
export function tryImageVariant(contentFile: ContentFile, sizeVariant?: ContentFileImageVariant) {
  // Default to the large variant, to avoid loading huge images.
  sizeVariant ||= ContentFileImageVariant.Large

  if (!contentFile.imageVariantUrls) return contentFile.fileUrl

  return contentFile.imageVariantUrls[sizeVariant] || contentFile.fileUrl
}
