<script setup lang="ts">
import type { QuoteItem } from '#imports'
import { tryImageVariant } from '#imports'

defineProps<{
  title?: string
  image?: ContentFile
  quotes: QuoteItem[]
}>()

const { cardRadiusClass } = useThemeClasses()
</script>

<template>
  <ContentBlockViewPartialContainer
    class="space-y-4"
    background-color="transparent"
    padding="default"
  >
    <div v-if="title" class="order-first">
      <ContentBlockViewPartialTitle :title="title" />
    </div>

    <div class="grid grid-cols-1 gap-4 md:grid-cols-2">
      
      <div
        v-if="image"
        class="order-last h-full min-h-[280px] md:order-first"
      >
        <img
          :src="tryImageVariant(image)"
          :alt="`Afbeelding bij ${title}`"
          class="size-full object-cover object-center"
          :class="cardRadiusClass"
        >
      </div>

      <ContentBlockViewPartialQuote :quotes="quotes" />
    </div>
  </ContentBlockViewPartialContainer>
</template>

<style scoped>
/* Optional: Add responsive heights using CSS */
@screen lg {
  .h-\[500px\] {
    height: 600px;
  }
}
</style>
