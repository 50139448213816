<script setup lang="ts">
const { t } = useI18n({ useScope: 'local' })
const showAdvanced = ref(false)
</script>

<template>
  <div class="mt-6 border-t border-gray-200 pt-4">
    <button
      type="button"
      class="flex items-center gap-x-2 text-sm text-gray-600"
      @click="showAdvanced = !showAdvanced"
    >
      <Icon
        :name="showAdvanced ? 'ph:caret-down' : 'ph:caret-right'"
        class="size-4"
      />
      {{ t('advancedSettings') }}
    </button>

    <div v-if="showAdvanced" class="mt-4">
      <BaseMessage
        icon
        type="warning"
        class="mb-4"
      >
        {{ t('advancedSettingsWarning') }}
      </BaseMessage>

      <FormField
        name="settings.marginTop.desktop"
        :label="t('marginTopDesktop')"
        type="number"
      />

      <FormField
        name="settings.marginTop.mobile"
        :label="t('marginTopMobile')"
        type="number"
      />

      <FormField
        name="settings.marginBottom.desktop"
        :label="t('marginBottomDesktop')"
        type="number"
      />

      <FormField
        name="settings.marginBottom.mobile"
        :label="t('marginBottomMobile')"
        type="number"
      />
    </div>
  </div>
</template>

<i18n lang="json">
{
  "en": {
    "advancedSettings": "Advanced settings",
    "advancedSettingsWarning": "Use these settings only if you know what you're doing! Misusers of this feature may face the Volunteerly style guide. 🚔",
    "marginTopDesktop": "Top margin (Desktop)",
    "marginTopMobile": "Top margin (Mobile)",
    "marginBottomDesktop": "Bottom margin (Desktop)",
    "marginBottomMobile": "Bottom margin (Mobile)"
  },
  "nl": {
    "advancedSettings": "Geavanceerde instellingen",
    "advancedSettingsWarning": "Gebruik deze instellingen alleen als je snapt wat je doet! Misbruikers van deze functie kunnen te maken krijgen met de Volunteerly-stijlpolitie. 🚔",
    "marginTopDesktop": "Bovenmarge (Desktop)",
    "marginTopMobile": "Bovenmarge (Mobile)",
    "marginBottomDesktop": "Onder marge (Desktop)",
    "marginBottomMobile": "Onder marge (Mobile)"
  }
}
</i18n>
