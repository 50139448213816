<script setup lang="ts">
import { ContentFileImageVariant, tryImageVariant } from '#imports'

const props = defineProps<{
  images: ContentFile[]
}>()

// For mas4you, we want to use just 3 images in a grid
// I made it possible to upload more, mainly for future use cases

// Only enabled when > 3 images
const buttonsEnabled = !!(props.images.length > 3)

// Carousel controls
const carousel = ref<HTMLElement | null>(null)
const showLeftButton = ref(false)
const showRightButton = ref(true)

const threshold = 10

const scroll = (direction: 'left' | 'right') => {
  if (!carousel.value) return

  const scrollAmount = direction === 'left' ? -300 : 300
  const currentScroll = carousel.value.scrollLeft
  const newScrollLeft = Math.max(0, currentScroll + scrollAmount)

  carousel.value.scrollTo({
    left: newScrollLeft,
    behavior: 'smooth',
  })

  // Check scroll position after animation completes
  setTimeout(toggleButtons, 300)
}

const toggleButtons = () => {
  if (!carousel.value) return

  const maxScrollLeft = carousel.value.scrollWidth - carousel.value.clientWidth
  showRightButton.value = carousel.value.scrollLeft < maxScrollLeft - threshold
  showLeftButton.value = carousel.value.scrollLeft > threshold
}

// Debounce the scroll event
const debouncedToggleButtons = useDebounceFn(toggleButtons, 100)

onMounted(() => {
  if (carousel.value) {
    carousel.value.addEventListener('scroll', debouncedToggleButtons)
    toggleButtons()
  }
})

onBeforeUnmount(() => {
  if (carousel.value) {
    carousel.value.removeEventListener('scroll', debouncedToggleButtons)
  }
})

const { cardRadiusClass } = useThemeClasses()
</script>

<template>
  <ContentBlockViewPartialContainer
    class="relative py-8"
    padding="default"
    background-color="transparent"
  >
    
    <div
      ref="carousel"
      :class="{
        'hidden-scrollbar flex gap-4 overflow-x-auto scroll-smooth': buttonsEnabled,
        'grid grid-cols-3 gap-4': !buttonsEnabled,
      }"
    >
      <div
        v-for="(image, index) in images"
        :key="index"
        class="grid-col-1 h-[280px] flex-none"
      >
        <img
          :src="tryImageVariant(image, ContentFileImageVariant.Large)"
          :alt="`Slide ${index + 1}`"
          class="size-full object-cover"
          :class="cardRadiusClass"
        >
      </div>
    </div>

    
    <div
      v-if="showLeftButton && buttonsEnabled"
      class="absolute left-0 top-1/2 -translate-y-1/2"
    >
      <BaseButtonIcon
        color="primary"
        shape="curved"
        @click="() => scroll('left')"
      >
        <Icon name="ph:arrow-left" />
      </BaseButtonIcon>
    </div>

    <div
      v-if="showRightButton && buttonsEnabled"
      class="absolute right-0 top-1/2 -translate-y-1/2"
    >
      <BaseButtonIcon
        color="primary"
        shape="curved"
        @click="() => scroll('right')"
      >
        <Icon name="ph:arrow-right" />
      </BaseButtonIcon>
    </div>
  </ContentBlockViewPartialContainer>
</template>

<style scoped>
.hidden-scrollbar {
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}
</style>
