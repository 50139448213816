// TODO:
// - Change the naming. The component for the form is called "AdvancedSettings", but it's a bit confusing.
export function useBlockSettings(blockData: Ref<ContentBlock>) {
  const { md } = useTailwindBreakpoints()

  // Somehow the styles didn't render on initial load, so we need to wait until the component is mounted.
  const mounted = ref(false)
  onMounted(() => mounted.value = true)

  const blockSettings = computed(() => blockData.value?.data?.settings)

  // Css styles based on the block settings
  const blockStyles = computed(() => {
    const settings = blockSettings.value
    if (!mounted.value || !settings) return {}

    const currentBreakpoint = md.value ? 'desktop' : 'mobile'
    // console.log('settings', settings)
    // console.log('currentBreakpoint', currentBreakpoint)

    return {
      marginTop: settings?.marginTop?.[currentBreakpoint] ? `${settings.marginTop?.[currentBreakpoint]}px` : undefined,
      marginBottom: settings?.marginBottom?.[currentBreakpoint] ? `${settings.marginBottom?.[currentBreakpoint]}px` : undefined,
    }
  })

  // Provide the block's settings and styles so components can inject them, if necessary.
  provide('blockSettings', blockSettings)
  provide('blockSettingsStyles', blockStyles)

  return {
    blockSettings,
    blockStyles,
  }
}
