<script setup lang="ts">
defineProps<{
  title?: string
}>()

const contentBlockTitleSize = inject('contentBlockTitleSize', '4xl')

</script>

<template>
  <BaseHeading
    v-if="title"
    as="h3"
    :size="contentBlockTitleSize"
    class="text-theme-content col-span-full"
  >
    {{ title }}
  </BaseHeading>
</template>
