<script setup lang="ts">
import { object, string, array } from 'yup'

const { t } = useI18n()

const textWithUspsSchema = object({
  title: string().label(t('title')),
  body: string().label(t('body')),
  uspsTitle: string().label(t('uspsTitle')),
  primaryButton: object({
    label: string().label(t('primaryButton.label')),
    url: string().label(t('primaryButton.url')),
  }),
  secondaryButton: object({
    label: string().label(t('secondaryButton.label')),
    url: string().label(t('secondaryButton.url')),
  }),
  usps: array(string()).label(t('usps')),
})

const { fields, push, remove } = useFieldArray<string>('usps')

const pushNew = () => {
  push('')
}

onMounted(() => {
  if ((fields.value || []).length === 0) {
    pushNew()
  }
})

useBlockFormSchema(textWithUspsSchema)

</script>

<template>
  <div class="flex flex-col gap-y-4 pt-4">
    <BaseParagraph>
      {{ t('description') }}
    </BaseParagraph>

    <div class="flex flex-col gap-y-4">
      <FormField name="title" :label="t('title')" />
      <FormTextarea name="body" :label="t('body')" />
      <FormField name="uspsTitle" :label="t('uspsTitle')" />
      <FormField name="primaryButton.label" :label="t('primaryButton.label')" />
      <FormField name="primaryButton.url" :label="t('primaryButton.url')" />
      <FormField name="secondaryButton.label" :label="t('secondaryButton.label')" />
      <FormField name="secondaryButton.url" :label="t('secondaryButton.url')" />

      
      

      <ContentBlockFormPartialRepeatable
        :title="t('uspsTitle')"
        :empty-message="t('emptyMessage')"
        :items="fields"
        @add="pushNew"
      >
        <template #default="{ index }">
          <ContentBlockFormPartialRepeatableItem
            v-if="index !== undefined"
            :index="index"
            title="USP"
            @remove="remove(index)"
          >
            <Field
              v-slot="{ field, errorMessage }"
              :name="`usps[${index}]`"
            >
              <BaseInput
                v-bind="field"
                :error="errorMessage"
              />
            </Field>
          </ContentBlockFormPartialRepeatableItem>
        </template>

        <template #add-button-text>
          {{ $t('addUsp') }}
        </template>
      </ContentBlockFormPartialRepeatable>
    </div>
  </div>
</template>

<i18n>
{
  "en": {
    "description": "A block to show a text, with a number of \"Unique Selling Points\" (USPs) and two buttons.",
    "addUsp": "Add USP",
    "title": "Title",
    "body": "Body",
    "uspsTitle": "Title above the list of USPs (optional)",
    "primaryButton": {
      "label": "Primary button label",
      "url": "Primary button url"
    },
    "secondaryButton": {
      "label": "Secondary button label",
      "url": "Secondary button url"
    },
    "emptyMessage": "You have not added any USPs yet.",
    "usp": "USP"
  },
  "nl": {
    "description": "Een blok om een tekst te tonen, met een aantal \"Unique Selling Points\" (USP's) en twee knoppen.",
    "addUsp": "Voeg USP toe",
    "title": "Titel",
    "body": "Body",
    "uspsTitle": "Titel boven lijstje met USPs (optioneel)",
    "primaryButton": {
      "label": "Primaire knop label",
      "url": "Primaire knop url"
    },
    "secondaryButton": {
      "label": "Secundaire knop label",
      "url": "Secundaire knop url"
    },
    "emptyMessage": "Je hebt nog geen USPs toegevoegd.",
    "usp": "USP"
  }
}
</i18n>
