<script setup lang="ts">
// TODO:
// - see todo notes below about width of caption
// - also think about a negative bottom margin to create overlay effect for the next section
// - dark image overlay for the large variant? (or a flag for it?)
// -

const { image,
  altText = '',
  caption = '',
  variant = 'default',
  // By default, limit to large size to avoid loading huge images.
  sizeVariant = ContentFileImageVariant.Large,
  maxWidth,
} = defineProps<{
  image: ContentFile
  altText?: string
  caption?: string
  // 'centered' would be a better name instead of 'default', but we need to data-migrate if we change this.
  variant?: 'default' | 'full-width' | 'left-aligned'
  sizeVariant?: ContentFileImageVariant
  maxWidth?: number
}>()

const imgStyle = computed(() => {
  if (maxWidth) {
    return `max-width: ${maxWidth}px`
  }
  return ''
})

// We prefer the large image variant, but fall back to the original file URL.
const imageVariantUrl = tryImageVariant(image, sizeVariant)

</script>

<template>
  <ContentBlockViewPartialContainer
    padding="none"
    class=""
    :class="{
      'col-span-12 flex flex-col items-center justify-center': variant === 'default',
      'col-span-12 flex flex-col items-start justify-center': variant === 'left-aligned',
    }"
  >
    <template v-if="imageVariantUrl && (variant === 'default' || variant === 'left-aligned')">
      
      <div v-if="variant == 'default'" class="grid grid-cols-12">
        <img
          :src="imageVariantUrl"
          class="col-span-12 rounded-xl md:col-span-8 md:col-start-3"
          :alt="altText"
          :style="imgStyle"
        >
      </div>
      
      <img
        :src="imageVariantUrl"
        class="col-rounded-xl"
        :alt="altText"
        :style="imgStyle"
      >
      <span v-if="caption" class="text-muted-400 mt-1 text-sm">{{
        caption
      }}</span>
    </template>

    <div v-else-if="variant === 'full-width'" class="relative">
      
      
      
      <div class="absolute left-12 top-12 z-10 md:max-w-xs">
        <ContentBlockViewPartialTitle
          v-if="caption"
          :title="caption"
          class="text-white"
        />
      </div>

      <img
        :src="imageVariantUrl"
        :alt="altText"
        class="w-full rounded-xl"
        :style="imgStyle"
      >
    </div>
  </ContentBlockViewPartialContainer>
</template>
