<script setup lang="ts">
// TODO:
// - Determine based on theme config if the buttons should be rounded or not.
// - Maybe this component can be removed after upgrading Tairo, that has better button features.
// - Maybe rename it to "ThemeButton" or "ThemedButton"?

// 'outline' | 'light' | 'primary' | 'secondary' | 'white' | 'outline-secondary'

const { variant = ContentButtonVariant.Primary } = defineProps<{
  variant?: ContentButtonVariant
}>()

const variantClass = computed(() => {
  switch (variant) {
    case 'primary':
      return 'bg-primary-500 text-white hover:bg-primary-600 font-semibold border-none'

    case 'secondary':
      return 'bg-secondary-500 text-white hover:bg-secondary-600 font-semibold border-none'

    // Style used in the VacancyCategories block, on a colored background.
    case 'outline':
      return 'bg-transparent border-white/80 text-white hover:bg-white/10'

    case 'outline-secondary':
      return 'bg-transparent border-secondary-500 text-secondary-500 hover:bg-secondary-500/10'

    // Light background with primary text color.
    // Only use this on a colored background.
    case 'light':
      // Note that I made the text darker.
      return 'bg-white/80 bg-transparent text-primary-700 hover:bg-white/70'

    // White background with secondary text color.
    case 'white':
      return 'bg-white text-secondary-500 hover:bg-white/80 font-semibold border border-secondary-500 hover:bg-secondary-500/10'
  }
})
</script>

<template>
  <NuxtLink
    class="text-md hover:bg-primary-400 rounded-full border px-6 py-2"
    :class="variantClass"
  >
    <slot />
  </NuxtLink>
</template>
