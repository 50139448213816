<script setup lang="ts">
import { type ImageWithButtonsBlockStyles as StyleType } from '#imports'

// TODO:
// - We could add a backgrounc color and text color prop, for more customization.

const { image, altText, headingText, bodyText, styling } = withDefaults(
  defineProps<{
    styling: StyleType
    image?: ContentFile
    isSwapped?: boolean
    altText?: string
    headingText: string
    bodyText: string
    primaryButtonText?: string
    primaryButtonUrl?: string
    secondaryButtonText?: string
    secondaryButtonUrl?: string
  }>(),
  {
    styling: ImageWithButtonsBlockStyles.HeroLarge,
    image: undefined,
    altText: '',
    headingText: '',
    bodyText: '',
    primaryButtonText: '',
    primaryButtonUrl: '',
    secondaryButtonText: '',
    secondaryButtonUrl: '',
  },
)

const isCallout = styling == 'callout' || styling == 'callout_colored'
const hasColoredBackground = styling == 'callout_colored'
const hasImage = !!image

// 'isStagedoos' means 'has new theme'
const isStagedoos = useRuntimeConfig().public.isStagedoos

const backgroundColorClass = computed(() => {
  if (hasColoredBackground) {
    // Quickfix, secondary looked really bad for meedoen/volunteerly or didn't work. We need to add this to the component form.
    return isStagedoos ? 'bg-secondary-800' : 'bg-primary-800'
  }
  return 'bg-muted-200'
})

const textColorClass = computed(() => {
  if (hasColoredBackground) {
    return 'text-white dark:text-white' // assuming it's a dark background
  }
  return 'text-muted-900 dark:text-muted-300'
})

const { cardRadiusClass } = useThemeClasses()

</script>

<template>
  
  <ContentBlockViewPartialContainer
    padding="default"
    class="relative col-span-12 gap-y-6 md:grid md:grid-cols-12"
    :class="{
      'bg-muted-200 full-width-bg': styling == 'hero_medium',
      'border-1 border-primary-300 dark:border-primary-800 my-10 overflow-hidden border-solid':
        isCallout,
      'py-10': !isCallout,
      'py-16': !hasImage,
      [cardRadiusClass]: isCallout,
      [backgroundColorClass]: hasColoredBackground,
    }"
  >
    
    <template v-if="image">
      <ContentBlockViewPartialSquaredImage
        v-if="!isSwapped && hasImage"
        :image="image"
        :alt="altText"
        :class="{
          [cardRadiusClass]: !isCallout,
        }"
        class="z-10"
      />
    </template>

    <div
      class="z-10 my-10 ml-0 flex flex-col justify-center gap-4 pt-6 md:mt-0 md:pl-0 md:pr-10"
      :class="{
        'col-span-6': hasImage,
        'col-span-12 md:pr-16': !hasImage,
        'ml-0 md:ml-14': !isSwapped && hasImage,
        'px-8 md:ml-10': !hasImage || isCallout,
        'my-4': !hasImage,
      }"
    >
      <BaseHeading
        class="z-20"
        :class="{
          'max-w-md': styling == 'hero_large',
          [textColorClass]: true,
        }"
        :size="styling == 'hero_large' ? '6xl' : '4xl'"
        lead="tight"
        as="h3"
      >
        {{ headingText }}
      </BaseHeading>

      <BaseParagraph
        class="z-20 max-w-[90%]"
        :class="{
          [textColorClass]: true,
          'md:max-w-sm': styling == 'hero_large' && hasImage,
          'md:max-w-md': styling != 'hero_large' && hasImage,
          'md:mr-20 lg:ml-96': !hasImage,
        }"
        :size="styling == 'hero_large' || !hasImage ? 'xl' : 'lg'"
      >
        {{ bodyText }}
      </BaseParagraph>

      <div
        v-if="primaryButtonText || secondaryButtonText"
        class="z-20 flex flex-col items-stretch gap-2 md:flex-row md:items-start"
        :class="{ 'md:mr-20 lg:ml-96': !hasImage }"
      >
        
        
        <BaseButton
          v-if="primaryButtonText"
          color="primary"
          class="mt-4"
          shape="curved"
          :to="primaryButtonUrl"
        >
          
          <span class="font-lg block px-2 text-lg">{{
            primaryButtonText
          }}</span>
        </BaseButton>

        
        <TextButtonLink
          v-if="secondaryButtonText"
          class="mt-4"
          :to="secondaryButtonUrl"
        >
          {{ secondaryButtonText }}
        </TextButtonLink>
      </div>
    </div>

    <template v-if="image">
      <ContentBlockViewPartialSquaredImage
        v-if="isSwapped && hasImage"
        :image="image"
        :class="{
          'rounded-3xl': !isCallout,
        }"
        class="z-10"
        :alt="altText"
      />
    </template>
  </ContentBlockViewPartialContainer>
</template>

<style scoped>
.full-width-bg::before {
  content: '';
  @apply bg-muted-100 absolute left-1/2 top-0 z-10 h-full w-screen -translate-x-1/2;
  /* background-color: rgb(var(--color-muted-100)); */
  @apply dark:bg-muted-800; /* Assuming you have a muted-500 color for dark mode */
}

.border-1 {
  border-width: 1px;
}
</style>
