import type { Component } from 'vue'

// Process components from a glob result into a map
function processComponents(components: Record<string, { default: Component }>) {
  const map = {} as Record<string, Component>

  for (const path in components) {
    const match = path.match(/\/(form|view)\/(.+)\.vue$/)
    if (match) {
      const componentName = match[2]
      map[componentName] = components[path].default
    }
  }

  return map
}

// This composable returns 2 maps:
// - A map of the form components for each content block type.
// - A map of the view components for each content block type.
export default function useContentBlockMaps() {
  // Load components from the .app layer
  const baseFormComponents = import.meta.glob<{ default: Component }>(
    '../../.app/components/content/block/form/*.vue',
    { eager: true },
  )
  const baseViewComponents = import.meta.glob<{ default: Component }>(
    '../../.app/components/content/block/view/*.vue',
    { eager: true },
  )

  // Load components from the current layer
  const currentFormComponents = import.meta.glob<{ default: Component }>(
    '/components/content/block/form/*.vue',
    { eager: true },
  )
  const currentViewComponents = import.meta.glob<{ default: Component }>(
    '/components/content/block/view/*.vue',
    { eager: true },
  )

  // Process components from both layers
  const baseFormMap = processComponents(baseFormComponents)
  const baseViewMap = processComponents(baseViewComponents)
  const currentFormMap = processComponents(currentFormComponents)
  const currentViewMap = processComponents(currentViewComponents)

  // console.log('baseViewMap', baseViewMap)
  // console.log('currentViewMap', currentViewMap)

  // Merge maps, with current layer taking precedence
  const contentBlockFormMap = { ...baseFormMap, ...currentFormMap }
  const contentBlockViewMap = { ...baseViewMap, ...currentViewMap }

  // console.log('contentBlockFormMap', contentBlockFormMap)
  // console.log('contentBlockViewMap', contentBlockViewMap)

  return { contentBlockFormMap, contentBlockViewMap }
}
