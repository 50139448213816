<script setup lang="ts">
import { object, string } from 'yup'

const { t } = useI18n()

// Schema definition
const dualCtaSchema = object({
  leftTitle: string().required().label(t('fields.title')),
  leftBody: string().required().label(t('fields.description')),
  leftButtonText: string().required().label(t('fields.buttonText')),
  leftButtonUrl: string().required().label(t('fields.buttonUrl')),

  rightTitle: string().required().label(t('fields.title')),
  rightBody: string().required().label(t('fields.description')),
  rightButtonText: string().required().label(t('fields.buttonText')),
  rightButtonUrl: string().required().label(t('fields.buttonUrl')),
})

// Inject the block form schema
useBlockFormSchema(dualCtaSchema)
</script>

<template>
  <div class="flex flex-col gap-y-4 pt-4">
    <BaseParagraph>
      {{ t('description') }}
    </BaseParagraph>
    
    <div class="border-primary-500 rounded-xl border p-4">
      <h3 class="mb-3 font-medium">
        {{ t('leftCta.title') }}
      </h3>
      <div class="flex flex-col gap-y-4">
        <FormField name="leftTitle" :label="t('fields.title')" />
        <FormTextarea
          name="leftBody"
          :label="t('fields.description')"
        />
        <FormField name="leftButtonText" :label="t('fields.buttonText')" />
        <FormField name="leftButtonUrl" :label="t('fields.buttonUrl')" />
      </div>
    </div>

    
    <div class="border-primary-500 rounded-xl border p-4">
      <h3 class="mb-3 font-medium">
        {{ t('rightCta.title') }}
      </h3>
      <div class="flex flex-col gap-y-4">
        <FormField name="rightTitle" :label="t('fields.title')" />
        <FormTextarea
          name="rightBody"
          :label="t('fields.description')"
        />
        <FormField name="rightButtonText" :label="t('fields.buttonText')" />
        <FormField name="rightButtonUrl" :label="t('fields.buttonUrl')" />
      </div>
    </div>
  </div>
</template>

<i18n lang="json">
{
  "en": {
    "description": "With this block you place two 'call to action' blocks next to each other, with each their own title, description and action button.",
    "leftCta": {
      "title": "Left side"
    },
    "rightCta": {
      "title": "Right side"
    },
    "fields": {
      "title": "Title",
      "description": "Description",
      "buttonText": "Button Text",
      "buttonUrl": "Button URL"
    }
  },
  "nl": {
    "description": "Met dit blok plaats je twee 'call to action' blokken naast elkaar, met ieder hun eigen titel, beschrijving en actieknop.",
    "leftCta": {
      "title": "Linkerkant"
    },
    "rightCta": {
      "title": "Rechterkant"
    },
    "fields": {
      "title": "Titel",
      "description": "Beschrijving",
      "buttonText": "Knoptekst",
      "buttonUrl": "Knop URL"
    }
  }
}
</i18n>
