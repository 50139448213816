<script setup lang="ts">
defineProps<{
  title?: string
  description?: string
  buttonText?: string
  buttonLink?: string
}>()

// @Maud:
// - Buttons rounding/size is not consistent
// - Colors are not consistent
// - gap-3 instead of gap-4 or gap-2?

// TODO: move this component to the Stagedoos layer?
// const title = 'Stages die bij jou passen'
// const description = 'Vind de stage die bij je past.'

const { categories } = await useCategories()

const { findSystemPageByIdentifier } = useSystemPages()
const vacancySearchPage = findSystemPageByIdentifier('vacancy-search')
</script>

<template>
  <ContentBlockViewPartialContainer background-color="primary">
    <div class="flex flex-col gap-y-4">
      <BaseHeading
        v-if="title"
        as="h2"
        size="4xl"
      >
        {{ title }}
      </BaseHeading>

      <BaseParagraph v-if="description">
        {{ description }}
      </BaseParagraph>

      <nav v-if="categories" class="my-4 flex flex-row flex-wrap gap-3">
        <BaseContentButton
          v-for="category in categories"
          :key="category.id"
          :to="`${vacancySearchPage?.path}?filter[categories_id_in][]=${category.id}`"
          variant="light"
        >
          {{ category.name }}
        </BaseContentButton>
      </nav>

      <div class="flex md:justify-end">
        <BaseContentButton
          v-if="buttonLink"
          :to="buttonLink"
          variant="secondary"
        >
          {{ buttonText }}
        </BaseContentButton>
      </div>
    </div>
  </ContentBlockViewPartialContainer>
</template>
